import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
	{
		path: '',
		loadChildren: () => import('./home/home.module').then(m => m.HomePageModule)
	},
	{
		path: 'reveal-word',
		loadChildren: () => import('./modals/reveal-word/reveal-word.module').then(m => m.RevealWordPageModule)
	},
  {
    path: 'stats',
    loadChildren: () => import('./modals/stats/stats.module').then( m => m.StatsPageModule)
  },
  {
    path: 'gifts',
    loadChildren: () => import('./modals/gifts/gifts.module').then( m => m.GiftsPageModule)
  },
  {
    path: 'videos',
    loadChildren: () => import('./modals/videos/videos.module').then( m => m.VideosPageModule)
  },
  {
    path: 'hint-prompt',
    loadChildren: () => import('./modals/hint-prompt/hint-prompt.module').then( m => m.HintPromptPageModule)
  },
  {
    path: 'bodie-wheel',
    loadChildren: () => import('./modals/bodie-wheel/bodie-wheel.module').then( m => m.BodieWheelPageModule)
  }
];

@NgModule({
	imports: [
		RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
	],
	exports: [RouterModule]
})
export class AppRoutingModule { }
